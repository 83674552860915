import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'

import Layout from '../components/Layout'
import H1 from '../components/H1'

const Gallery = (props) => {
  console.log('gallery', props)
  const images = props.data.images.edges
  return (
    <Layout>
      <H1>Galeria</H1>
      <GalleryWrapper>
      {images.map((image,index) => {
        const img = image.node.childImageSharp.fluid
        return <ImgStyled fluid={img} key={index}/>
      })}
      </GalleryWrapper>
    </Layout>
  )
}

const GalleryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -10px;
  flex-flow: row wrap;
  box-sizing: border-box;
`

const ImgStyled = styled(Img)`
  flex-grow: 0;
  flex-shrink: 1;
  flex: 1 1 100%;
  height: auto;
  margin: 5px;
  box-sizing: border-box;
  
  @media (min-width: 481px) {
    flex-basis: calc(50% - 10px);
  }

  @media (min-width: 960px) {
    flex-basis: calc(33.3333% - 10px);
    height: 294px;
  }
`

export default Gallery

export const query = graphql`
query PhotosQuery {
  images: allFile(filter: {relativePath: {regex: "/images/gallery/"}}) {
    edges {
      node {
        childImageSharp {
          fluid(maxWidth: 300) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }
  }
}
`
